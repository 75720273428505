
import { defineComponent, ref, computed } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import ComposeSideButtonInsertTag from '@/components/shared/compose/sideButtons/ComposeSideButtonInsertTag.vue'
import { capitalize } from '@/services/utils'
import { Form as Validation } from 'vee-validate'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  name: 'TemplatesTemplateModal',
  components: { ComposeSideButtonInsertTag, TmAlert, Validation, TmFormLine, TmButton, TmModal },
  props: {
    name: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'Create',
    },
    content: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const categoryOptions = ['General', 'Marketing', 'Support', 'Sales']
    const nameVal = ref(props.name)
    const contentVal = ref(props.content)
    const form = ref()
    const categoryVal = ref(props.category ? capitalize(props.category) : 'General')
    const messageLength = computed(() => contentVal.value.length)
    const maxLength = 918
    const isError = computed(() => messageLength.value > maxLength)

    const { isLoadingMode } = useModes()

    const handleClick = () => {
      form.value.$el.dispatchEvent(new Event('submit', { cancelable: true }))
    }

    return {
      isLoadingMode,
      messageLength,
      maxLength,
      isError,
      nameVal,
      handleClick,
      form,
      contentVal,
      categoryVal,
      categoryOptions,
    }
  },
})
